import React, { PropsWithChildren } from 'react';

import { PageHeader, PageHeaderContent } from './RMPageHeader.styles.js';

type RMPageHeaderProps = PropsWithChildren;

export function RMPageHeader({ children }: RMPageHeaderProps) {
  return (
    <PageHeader>
      <PageHeaderContent>{children}</PageHeaderContent>
    </PageHeader>
  );
}
