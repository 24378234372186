import { PropsWithChildren } from 'react';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMIconButton } from '../RMIconButton/RMIconButton';

import { Left, PageHeader, Wordmark } from './RMPageHeader.mobile.styles.js';

type RMPageHeaderMobileProps = PropsWithChildren<{
  onOpenNavbar: () => void;
}>;

export function RMPageHeaderMobile({ onOpenNavbar, children }: RMPageHeaderMobileProps) {
  const isMobile = useIsMobileViewport();

  if (!isMobile) {
    return null;
  }

  return (
    <PageHeader>
      <Left>
        <RMIconButton icon={faBars} tooltip={null} color="white" backgroundColor="transparent" onClick={onOpenNavbar} />
        <Wordmark color="neutral" />
      </Left>

      {children}
    </PageHeader>
  );
}
