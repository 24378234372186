import { styled } from '@linaria/react';

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 85rem;
  margin: 0 auto;
  position: sticky;
  top: 0;
  background-color: var(--surface);
  z-index: 15;
  width: 100%;

  &[data-mobile='true'] {
    position: static;
  }
`;
