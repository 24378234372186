import { styled } from '@linaria/react';

export const PageHeader = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;

  border-bottom: 1px solid var(--color-neutral-87);

  body[data-mobile='true'] & {
    position: static;
    top: auto;
    z-index: 0;
    border-bottom: none;
  }
`;

export const PageHeaderContent = styled.div`
  background-color: var(--surface);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-2xl) var(--spacing-4xl);
  gap: var(--spacing-lg);
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;

  body[data-mobile='true'] & {
    padding: calc(var(--spacing-sm) + var(--spacing-md)) var(--spacing-md) var(--spacing-xs);
    max-width: unset;
  }

  body[data-tablet='true'] & {
    padding: var(--spacing-2xl) var(--spacing-3xl);
  }
`;
