import { styled } from '@linaria/react';

import { RMCloseButton } from '../RMCloseButton/RMCloseButton';
import { RMText } from '../RMText/RMText';

export const StyledPageBanner = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--spacing-md);

  &[data-closeable='true'] {
    padding: var(--spacing-md) var(--spacing-4xl) var(--spacing-md) var(--spacing-md);
  }

  &[data-style='info'] {
    background-color: var(--banner-info);
  }

  &[data-style='danger'] {
    background-color: var(--color-red-95);
  }
`;

export const CloseButton = styled(RMCloseButton)`
  position: absolute;
  right: var(--spacing-md);

  body[data-mobile='true'] & {
    right: var(--spacing-xs);
  }

  > svg {
    color: var(--on-surface-primary) !important;
  }
`;

export const ClickableText = styled(RMText)`
  cursor: pointer;

  body[data-mobile='false'] & {
    white-space: nowrap;
  }
`;
