import { RMPageBanner } from './RMPageBanner.js';
import { RMPageContent } from './RMPageContent';
import { RMPageHeader } from './RMPageHeader.js';
import { RMPageHeaderMobile } from './RMPageHeader.mobile.js';
import { RMPageHeaderActions } from './RMPageHeaderActions.js';
import { RMPageRoot } from './RMPageRoot';
import { RMPageTitle } from './RMPageTitle';

export const RMPage = {
  Root: RMPageRoot,
  HeaderMobile: RMPageHeaderMobile,
  Header: RMPageHeader,
  HeaderActions: RMPageHeaderActions,
  Title: RMPageTitle,
  Content: RMPageContent,
  Banner: RMPageBanner,
};
