import { styled } from '@linaria/react';

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-4xl) var(--spacing-4xl);
  max-width: 85rem;
  margin: 0 auto;
  overflow: var(--overflow);

  body[data-tablet='true'] & {
    padding: var(--spacing-2xl) var(--spacing-3xl) var(--spacing-3xl);
  }

  body[data-mobile='true'] & {
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-2xl);
    max-width: unset;
  }
`;
