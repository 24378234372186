import { PropsWithChildren, ReactNode, useCallback } from 'react';

import { RMPage } from '@/components/RMPage';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { openNavbar, useNavbarStore } from '../states';

export function MobilePageHeaderContainer({ children }: PropsWithChildren) {
  const navbarStore = useNavbarStore();
  const mobile = useIsMobileViewport();

  const handleOpenNavbar = useCallback(() => {
    openNavbar(navbarStore);
  }, [navbarStore]);

  if (!mobile) {
    return null;
  }

  return <RMPage.HeaderMobile onOpenNavbar={handleOpenNavbar}>{children}</RMPage.HeaderMobile>;
}
