import { PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMText } from '../RMText/RMText';

import { PageTitle } from './RMPageTitle.styles';

type RMPageTitleProps = PropsWithChildren<{
  title: string;
}>;

export function RMPageTitle({ title, children }: RMPageTitleProps) {
  const mobile = useIsMobileViewport();

  return (
    <PageTitle id="page-header-title" data-mobile={mobile}>
      <RMText type="serif" size={mobile ? 'l' : 'xl'} color="on-surface-primary">
        {title}
      </RMText>

      {children}
    </PageTitle>
  );
}
