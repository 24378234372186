import { styled } from '@linaria/react';

import { RMWordmark } from '../RMWordmark/RMWordmark';

export const PageHeader = styled.div`
  background-color: var(--inverse-surface);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-2xs);
  gap: var(--spacing-lg);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 20;
`;

export const Logo = styled.img`
  width: 2.1875rem;
  height: 2.1875rem;
`;

export const Wordmark = styled(RMWordmark)`
  width: 6.18rem;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;
