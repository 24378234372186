import { CSSProperties, PropsWithChildren } from 'react';

import { PageContent } from './RMPageContent.styles';

export type RMPageContentProps = PropsWithChildren<{
  overflow?: 'hidden';
}>;

export function RMPageContent({ overflow, children }: RMPageContentProps) {
  return <PageContent style={{ '--overflow': overflow } as CSSProperties}>{children}</PageContent>;
}
