import React, { PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import { PageHeaderActions } from './RMPageHeaderActions.styles.js';

type RMPageHeaderActionsProps = PropsWithChildren;

export function RMPageHeaderActions({ children }: RMPageHeaderActionsProps) {
  const isMobile = useIsMobileViewport();
  if (isMobile) {
    return null;
  }
  return <PageHeaderActions>{children}</PageHeaderActions>;
}
